<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="RFQs"
              url="/pages/sales-tools/rfq/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadRfq()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>

              <template #summaries>
                <DxSummary>
                  <DxGroupItem
                    column="id"
                    summary-type="count"
                    display-format="{0} orders"
                  />
                  <DxGroupItem
                    :show-in-group-footer="false"
                    :align-by-column="true"
                    column="total"
                    summary-type="max"
                    value-format="currency"
                  />
                  <DxGroupItem
                    :show-in-group-footer="true"
                    column="total"
                    summary-type="sum"
                    value-format="currency"
                    display-format="Total: {0}"
                  />
                </DxSummary>

                <DxSortByGroupSummaryInfo summary-item="count" />
              </template>
            </DxGrid>

            <div class="" v-if="selectedData.length > 0">
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="primary"
                size="sm"
                @click="actionStatus = 'print_batch'"
              >
                Print
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="success"
                size="sm"
                @click="actionStatus = 'confirm'"
              >
                Confirm
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="info"
                size="sm"
                @click="actionStatus = 'reopen'"
              >
                Reopen
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="danger"
                size="sm"
                @click="actionStatus = 'void'"
              >
                Void
              </b-button>

              <Modal @onChange="(v) => handleEvent(actionStatus, {})" />
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text>
            <ViewRfqDetail :rfq-data="toggledViewData" />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ViewRfqDetail from './QuickView.vue';

import rfqApi from '@api/rfq_headers';
import { queryParameters } from '@/schema';

const update = (data) => {
  rfqApi
    .update(data)
    .then(() => {
      //
    })
    .catch(() => {
      //
    })
    .finally(() => {
      //
    });
};

export default {
  name: 'RFQPage',
  components: {
    ViewRfqDetail,
  },
  data: () => ({
    keyword: '',
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    dataColumns: [
      { field: 'order_no', caption: 'Order No.' },
      { field: 'company', caption: 'Customer' },
      { field: 'display_date', caption: 'Order Date' },
      { field: 'display_salesperson', caption: 'Sales Person' },
      { field: 'display_total', caption: 'Total' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    selectedData: [],
    actionStatus: '',
  }),
  watch: {
    page(v) {
      this.loadRfq();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadRfq();
      }
    },
    keyword(v) {
      this.loadRfq();
    },
  },
  mounted() {
    this.loadRfq();
  },
  methods: {
    loadRfq() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      if (this.keyword) {
        params.filter = this.keyword;
      }

      rfqApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            for (const item of data) {
              const { order_date, total, salesperson } = item;

              item.display_date = this.displayDateTimeFromUnix(order_date);
              item.display_total = this.moneyFormat(total);

              if (salesperson && salesperson.data) {
                const { data } = salesperson;
                if (data) {
                  const { first_name, last_name } = data;
                  item.display_salesperson = first_name;

                  if (last_name) {
                    item.display_salesperson += ` ${last_name}`;
                  }
                }
              }
            }

            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        if (action == 'confirm') {
          this.selectedData.forEach((data) => {
            data.status = 'C';
            update(data);
          });
        }

        if (action == 'reopen') {
          this.selectedData.forEach((data) => {
            data.status = 'R';
            update(data);
          });
        }

        if (action == 'void') {
          this.selectedData.forEach((data) => {
            data.status = 'V';
            update(data);
          });
        }

        if (action == 'print_batch') {
          const ids = this.selectedData.map((x) => {
            return +x.id;
          });

          const data = {
            id: 1,
            generated_ids: ids,
          };

          rfqApi
            .generatemultiplepdf(data)
            .then((res) => {
              if (res) {
              }
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const { key: id } = event;

      const url = `/pages/sales-tools/rfq/view?id=${id}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
      rfqApi
        .update(data)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
          }
        })
        .catch((err) => {
          if (err) {
            this.toastConfig('', 'error');
          }
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      rfqApi
        .delete(event)
        .then(() => {
          this.loadRfq();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onPrintPDF(event) {
      const data = event?.row?.data;

      if (data) {
        rfqApi
          .generatepdf(data.id)
          .then((res) => {
            if (res) {
              this.openPdf(res);
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
  },
};
</script>

<style></style>
