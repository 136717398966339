import api from '@apiInstance';

export default {
  add (params) {
    return api.post('rfq_headers', params);
  },
  get (id) {
    return api.fetch(`rfq_headers/${id}/detail`);
  },
  async list (params) {
    const response = await api.fetch('/rfq_headers', { params });
    return response;
  },
  update (params) {
    return api.patch('rfq_headers', params);
  },
  delete (id) {
    return api.remove('rfq_headers', id);
  },

  saveToQuotations (id, body) {
    return api.request({
      url: `rfq_headers/${id}/savetoquotations`,
      method: 'POST',
      body,
      type: 'quotation_headers'
    });
  },

  duplicate (id, body) {
    return api.request({
      url: `rfq_headers/${id}/duplicate`,
      method: 'GET',
      body,
      type: 'rfq_headers'
    });
  },

  generatepdf (id) {
    return api.fetch(`rfq_headers/${id}/generatepdf`);
  },

  generatemultiplepdf (body) {
    return api.request({
      url: 'rfq_headers/generatemultiplepdfs',
      method: 'POST',
      body,
      type: 'generatemultiplepdfs'
    });
  }
};
